import htm from "htm";

export function html(
    strings: TemplateStringsArray,
    ...values: any[]
) {
    return <HResult>(Renderer: (...args: any[]) => HResult): HResult => {
        return (htm as any).call(Renderer, strings, ...values);
    };
}

export const jsx = html;